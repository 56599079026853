<template>
  <div
    class="tab-pane fade show active"
    id="data"
    role="tabpanel"
    aria-labelledby="data-tab"
  >
    <label for="name">Nome</label>
    <soft-field
      id="name"
      v-model="state.name"
      placeholder="Nome"
      name="name"
    />
    
    <label for="cpf">CPF</label>
    <soft-field
      id="cpf"
      v-model="state.cpf"
      placeholder="CPF"
      name="cpf"
    />
    
    <label for="rg">RG</label>
    <soft-field
      id="rg"
      v-model="state.rg"
      placeholder="RG"
      name="rg"
    />

    <label for="dob">Data de Nascimento</label>
    <soft-date-time-picker 
      id="dob"
      v-model="state.dob"
      placeholder="Data de Nascimento"
      name="dob"
      format="dd/MM/yyyy"
      model-type="yyyy-MM-dd"
      :enable-time-picker="false"
    />
    
    <label for="sex">Genêro</label>
    <soft-select
      id="sex"
      name="sex"
      v-model="state.sex"
      :options="[
        {value: 'M', label: 'Masculino'},
        {value: 'F', label: 'Feminino'},
        {value: 'P', label: 'Prefiro não dizer'},
      ]"
      placeholder="Selecione o genêro:"
    />

    <label for="occupation">Profissão</label>
    <soft-field
      id="occupation"
      v-model="state.occupation"
      placeholder="Profissão"
      name="occupation"
    />
    
    <label for="company">Empresa</label>
    <soft-select-search
      id="company"
      name="company"
      v-model="state.company"
      :options="companies"
      placeholder="Selecione a empresa"
    />
    
    <label for="clinic">Clínica</label>
    <soft-select-search
      id="clinic"
      name="clinic"
      v-model="state.clinic"
      :options="clinics"
      placeholder="Selecione a clínica"
    />
    
    <h3>Endereço</h3>

    <label for="zip_code">CEP (Somente Números)</label>
    <soft-field
      id="zip_code"
      v-model="state.zip_code"
      placeholder="CEP"
      name="zip_code"
      @input="handleCEPChange"
      debounce="500"
    />    

    <label for="address_street">Rua</label>
    <soft-field
      id="address_street"
      v-model="state.address_street"
      placeholder="Rua"
      name="address_street"
    />
    
    <label for="address_number">Número</label>
    <soft-field
      id="address_number"
      v-model="state.address_number"
      placeholder="Número"
      name="address_number"
    />
    
    <label for="address_neighborhood">Bairro</label>
    <soft-field
      id="address_neighborhood"
      v-model="state.address_neighborhood"
      placeholder="Bairro"
      name="address_neighborhood"
    />
    
    <label for="address_complement">Complemento</label>
    <soft-field
      id="address_complement"
      v-model="state.address_complement"
      placeholder="Complemento"
      name="address_complement"
    />
    
    <label for="address_city">Cidade</label>
    <soft-field
      id="address_city"
      v-model="state.address_city"
      placeholder="Cidade"
      name="address_city"
    />
    
    <label for="address_state">Estado</label>
    <soft-field
      id="address_state"
      v-model="state.address_state"
      placeholder="Estado"
      name="address_state"
    />
    
    <label for="address_country">País</label>
    <soft-field
      id="address_country"
      v-model="state.address_country"
      placeholder="País"
      name="address_country"
    />
    
    <h3>Contato</h3>

    <label for="email">E-mail</label>
    <soft-field
      id="email"
      v-model="state.email"
      placeholder="E-mail"
      name="email"
    />
    
    <h3>Outros</h3>
    
    <h3>Insira a foto do paciente</h3>
      <div class="">
        
        <soft-image-field 
          id="patient_img"
          name="patient_img"
          :initialValue="state.patientimg"
          v-model="state.patient_img"
        />
      <!-- <input
        type="file"
        accept="image/*"
        @change="handlePacientImageUpload"
        ref="patient_img"
      />
      <img
        v-if="state.patient_img"
        :src="state.patient_img"
        class="preview-image mt-2"
        alt="Prévia do Seguro"
        style="max-width: 100%; height: auto;"
      /> -->
        </div>
    <label for="health_type">Assistência Médica</label>
    <soft-select-search
      id="health_type"
      name="health_type"
      v-model="state.health_type"
      :options="HealthTypesOptions"
      placeholder="Selecione o formato"
    />

   

    <div v-if="state.health_type == HealthTypes.SUS">
    <label for="sus_card">N° da carteirinha do SUS</label>
    <soft-field
      id="sus_card"
      v-model="state.sus_card"
      placeholder="N° da carteirinha do SUS"
      name="sus_card"
    />
  </div>

    <div v-if="state.health_type == HealthTypes.HEALTH_INSURANCE">
      <label for="healthcare_operator_id">Operadora de saúde</label>
      <soft-select-search
        id="healthcare_operator_id"
        name="healthcare_operator_id"
        v-model="state.healthcare_operator_id"
        :options="healthcare_operators"
        placeholder="Selecione a operadora"
      />
    
      <label for="health_insurance">Plano de Saúde</label>
      <soft-field
        id="health_insurance"
        v-model="state.health_insurance"
        placeholder="Plano de Saúde"
        name="health_insurance"
      />
      <label for="health_card_number">Nº da Carteirinha</label>
        <soft-field
                  id="health_card_number"
          v-model="state.health_card_number"
          placeholder="Nº da Carteirinha"
          name="health_card_number"
        
        />

    </div>

    <soft-switch-model 
      id="active"
      name="active"
      v-model="state.active"
    >
      Ativo
    </soft-switch-model>

    <div v-if="checkMinor()">
      <h2>Responsável</h2>

      <label for="responsible_name">Nome</label>
      <soft-field
        id="responsible_name"
        v-model="state.responsible_name"
        placeholder="Nome"
        name="responsible_name"
      />
      
      <label for="responsible_cpf">CPF</label>
      <soft-field
        id="responsible_cpf"
        v-model="state.responsible_cpf"
        placeholder="CPF"
        name="responsible_cpf"
      />
      
      <label for="responsible_rg">RG</label>
      <soft-field
        id="responsible_rg"
        v-model="state.responsible_rg"
        placeholder="RG"
        name="responsible_rg"
      />
        <br>

      <label for="responsible_dob">Data de Nascimento</label>
      <soft-date-time-picker 
        id="responsible_dob"
        v-model="state.responsible_dob"
        placeholder="Data de Nascimento"
        name="responsible_dob"
        format="dd/MM/yyyy"
        model-type="yyyy-MM-dd"
        :enable-time-picker="false"
      />
      <br>
      <label for="responsible_sex">Gênero</label>
      <soft-select
        id="responsible_sex"
        name="responsible_sex"
        v-model="state.responsible_sex"
        :options="[
          {value: 'M', label: 'Masculino'},
          {value: 'F', label: 'Feminino'},
          {value: 'P', label: 'Prefiro não dizer'},
        ]"
        placeholder="Selecione o sexo"
      />

      <label for="responsible_occupation">Profissão</label>
      <soft-field
        id="responsible_occupation"
        v-model="state.responsible_occupation"
        placeholder="Profissão"
        name="responsible_occupation"
      />
      <br>
      <h3>Endereço</h3>

      <label for="responsible_zip_code">CEP (Somente Números)</label>
      <soft-field
        id="responsible_zip_code"
        v-model="state.responsible_zip_code"
        placeholder="CEP"
        name="responsible_zip_code"
        @input="handleResponsibleCEPChange"
        debounce="500"
      />    

      <label for="responsible_address_street">Rua</label>
      <soft-field
        id="responsible_address_street"
        v-model="state.responsible_address_street"
        placeholder="Rua"
        name="responsible_address_street"
      />
      
      <label for="responsible_address_number">Número</label>
      <soft-field
        id="responsible_address_number"
        v-model="state.responsible_address_number"
        placeholder="Número"
        name="responsible_address_number"
      />
      
      <label for="responsible_address_neighborhood">Bairro</label>
      <soft-field
        id="responsible_address_neighborhood"
        v-model="state.responsible_address_neighborhood"
        placeholder="Bairro"
        name="responsible_address_neighborhood"
      />
      
      <label for="responsible_address_complement">Complemento</label>
      <soft-field
        id="responsible_address_complement"
        v-model="state.responsible_address_complement"
        placeholder="Complemento"
        name="responsible_address_complement"
      />
      
      <label for="responsible_address_city">Cidade</label>
      <soft-field
        id="responsible_address_city"
        v-model="state.responsible_address_city"
        placeholder="Cidade"
        name="responsible_address_city"
      />
      
      <label for="responsible_address_state">Estado</label>
      <soft-field
        id="responsible_address_state"
        v-model="state.responsible_address_state"
        placeholder="Estado"
        name="responsible_address_state"
      />
      
      <label for="responsible_address_country">País</label>
      <soft-field
        id="responsible_address_country"
        v-model="state.responsible_address_country"
        placeholder="País"
        name="responsible_address_country"
      />
      <br>
      
      <h3>Contato</h3>

      <label for="responsible_email">E-mail</label>
      <soft-field
        id="responsible_email"
        v-model="state.responsible_email"
        placeholder="E-mail"
        name="responsible_email"
      />
      <br>


    </div>
  </div>
</template>

<script>
import HealthTypes, { HealthTypesOptions } from "../../constants/HealthTypes";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import SoftSelect from "../../components/SoftSelect.vue";
import SoftField from "../../components/SoftField.vue";
import moment from "moment";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";
import SoftDateTimePicker from "../../components/SoftDateTimePicker.vue";
import SoftImageField from "@/components/SoftImageField.vue";

export default {
  name: "PatientDataTab",
  props: ["modelValue"],
  components: {
    SoftSwitchModel,
    SoftSelect,
    SoftSelectSearch,
    SoftField,
    SoftDateTimePicker,
    SoftImageField,
  },
  data() {
    return {
      HealthTypesOptions,
      HealthTypes,
      modelType: "yyyy-MM-dd"
    }
  },
  computed: {
    state: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    },
    companies() {
      return this.$store.state.companies.options;
    },
    clinics() {
      return this.$store.state.companies.clinics;
    },
    healthcare_operators() {
      return this.$store.getters['healthcareOperators/options']
    }
  },
  methods: {
    checkMinor() {
      return moment().diff(this.state.dob, 'years') < 18;
    },

    async fetchAddressByCEP(cep, isResponsible = false) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json();
        if (response.ok) {
          if (isResponsible) {
            this.state.responsible_address_street = data.logradouro;
            this.state.responsible_address_neighborhood = data.bairro;
            this.state.responsible_address_city = data.localidade;
            this.state.responsible_address_state = data.uf;
            this.state.responsible_address_country = 'Brasil';
          } else {
            this.state.address_street = data.logradouro;
            this.state.address_neighborhood = data.bairro;
            this.state.address_city = data.localidade;
            this.state.address_state = data.uf;
            this.state.address_country = 'Brasil';
          }
        } else {
          console.error('Erro ao buscar o CEP:', data.message);
        }
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
    },
    handleCEPChange() {
      if (this.state.zip_code && this.state.zip_code.length === 8) {
        this.fetchAddressByCEP(this.state.zip_code);
      }
    },
    handleResponsibleCEPChange() {
      if (this.state.responsible_zip_code && this.state.responsible_zip_code.length === 8) {
        this.fetchAddressByCEP(this.state.responsible_zip_code, true);
      }
    },
  

  }
}
</script>